import gameManager from "./gameManager.js"
import seedrandom from "../_snowpack/pkg/seedrandom.js"
import { startOfDay } from "../_snowpack/pkg/date-fns.js"

let seededRandom
if (gameManager.currentGame.seedState == null) {
  seededRandom = seedrandom(startOfDay(new Date()), { state: true })
} else {
  seededRandom = seedrandom("", { state: gameManager.currentGame.seedState })
}

export default seededRandom
